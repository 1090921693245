import { Box, Checkbox, Heading, Text } from '@chakra-ui/react';
import AvatarLetter from '@components/AvatarLetter';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

type ParticipantsListCheckboxProps = {
  onSelectionChange: (selected: string[]) => void;
  selectAll: boolean | null;
  participants: any[];
  updatedList: any[];
};

export default function ParticipantsListCheckbox({
  onSelectionChange,
  selectAll,
  participants,
  updatedList,
}: ParticipantsListCheckboxProps) {
  const [checkedParticipants, setCheckedParticipants] = useState<any[]>([]);

  useEffect(() => {
    handleSelectAll();
  }, [selectAll]);

  useEffect(() => {
    setCheckedParticipants(updatedList);
  }, [updatedList]);

  const handleSelectionChange = (selected: any) => {
    onSelectionChange(selected);
  };

  const handleSelect = (participantEmail: string) => {
    const participant = participants.find((elt) => elt.email === participantEmail);
    const newSelection = checkedParticipants.includes(participant)
      ? checkedParticipants.filter((elt) => elt !== participant)
      : [...checkedParticipants, participant];

    setCheckedParticipants(newSelection);
    handleSelectionChange(newSelection);
  };

  const handleSelectAll = () => {
    const newSelection =
      selectAll !== null && checkedParticipants.length < participants.length
        ? participants
        : [];
    setCheckedParticipants(newSelection);
    handleSelectionChange(newSelection);
  };

  return (
    <Wrapper>
      {participants.map((participant: any) => (
        <Checkbox
          key={participant.email}
          value={participant.email}
          isChecked={!!checkedParticipants.find((elt) => elt.email === participant.email)}
          onChange={(e) => handleSelect(e.target.value)}>
          <Participant>
            {participant.isNewParticipant ? (
              <>
                <AvatarLetter
                  backgroundColor={participant.bgAvatar}
                  text={participant.email}
                />
                <Box ml="12px">
                  <Heading wordBreak="break-all" size="sm">
                    {participant.email}
                  </Heading>
                </Box>
              </>
            ) : (
              <>
                <img src={participant.profilePicture} alt="" />
                <Box ml="12px">
                  <Heading wordBreak="break-all" size="sm">
                    {participant.name}
                  </Heading>
                  <Text wordBreak="break-all" fontSize="xs" color="black60">
                    {participant.email}
                  </Text>
                </Box>
              </>
            )}
          </Participant>
        </Checkbox>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 40vh;
  overflow-y: auto;
`;
const Participant = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;
