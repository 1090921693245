import EarthImage from '@assets/icons/earth-image.png';
import { ReactComponent as EarthPoints } from '@assets/icons/earth-points.svg';
import { ReactComponent as LogoTheTreep } from '@assets/icons/logo_thetreep.svg';
import { ReactComponent as LogoSog } from '@assets/icons/stayorgo-logo.svg';
import { Heading, Text } from '@chakra-ui/react';
import { LoginCard } from '@features/account/components/LoginCard';
import { colors } from '@theme/colors';
import styled from 'styled-components';

export default function Login() {
  return (
    <LoginPage>
      <SectionLogin>
        <LogosWrapper>
          <LogoSog />
          <LogoTheTreep className="logo-treep" />
        </LogosWrapper>
        <Heading color={colors.black} size="xl" margin="32px 0 12px 0">
          {'Bienvenue sur Stay or Go'}
        </Heading>
        <Text color={colors.black60} fontSize="md">
          {`Créez un compte sur Stay or Go, commencez à évaluer la pertinence de vos déplacements professionnels et comparez entre le présentiel et le distanciel pour vos événements.`}
        </Text>
        <LoginCard />
        <CompanyInfos>
          <Text>{'Stay or Go un service opéré par the Treep SAS'}</Text>
          <Text>{'1 Carrefour de Longchamp 75116 Paris'}</Text>
          <Text>{'SIREN : 820330504'}</Text>
        </CompanyInfos>
      </SectionLogin>
      <SectionDescription>
        <DescriptionWrapper>
          <Earth src={EarthImage} />
          <Heading
            textAlign="center"
            color={colors.black}
            maxWidth="400px"
            size="xl"
            margin="16px 0 12px 0">
            {"Boostez vos voyages d'affaires avec Stay or Go"}
          </Heading>
          <Text textAlign="center" color={colors.black60} fontSize="m">
            {`Voici Stay or Go : un outil d'aide à la décision qui permet de choisir entre le distanciel et le présentiel pour ses rendez-vous professionnels.`}
          </Text>
        </DescriptionWrapper>
        <BackgroundWrapper>
          <EarthPoints />
        </BackgroundWrapper>
      </SectionDescription>
    </LoginPage>
  );
}

const LoginPage = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  .authCard {
    border: none;
  }
`;

const SectionLogin = styled.div`
  width: 50%;
  background: ${colors.white};
  padding: 80px 80px 0 80px;
  .accountCard {
    width: 100%;
  }
  .authCard {
    width: 100%;
    min-width: initial;
    max-width: 700px;
    h2 {
      display: none;
    }
  }
`;

const LogosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  .logo-treep {
    height: 32px;
    width: 128px;
  }
`;
const CompanyInfos = styled.div`
  font-size: 10px;
  margin: 24px auto 32px auto;
  text-align: center;
  p {
    font-size: 13px;
  }
`;

const SectionDescription = styled.div`
  width: 50%;
  padding: 80px;
  position: relative;
  display: flex;
`;

const Earth = styled.img`
  width: 100%;
  max-width: 176px;
  position: relative;
  z-index: 1;
`;

const DescriptionWrapper = styled.div`
  position: relative;
  display: flex;
  margin-top: 160px;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;
const BackgroundWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  svg {
    width: 100%;
    height: auto;
    max-width: 1200px;
  }
`;
