import { ReactComponent as CloseIcon } from '@assets/icons/close-circle.svg';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import AvatarLetter from '@components/AvatarLetter';
import styled from 'styled-components';
type ParticipantsListCheckboxProps = {
  onSelectionChange: (selected: string[]) => void;
  guests: any[];
};

export default function GuestList({
  onSelectionChange,
  guests,
}: ParticipantsListCheckboxProps) {
  const handleDeleteGuest = (guestEmail: string) => {
    onSelectionChange(guests.filter((guest) => guest.email !== guestEmail));
  };
  const handleDeleteAll = () => {
    onSelectionChange([]);
  };
  return (
    <Wrapper>
      <ListContainer>
        {guests.map((guest: any) => (
          <Guest key={guest.email}>
            {guest.isNewParticipant ? (
              <>
                <AvatarLetter backgroundColor={guest.bgAvatar} text={guest.email} />
                <Box ml="12px" mr="6px">
                  <Heading wordBreak="break-all" size="sm">
                    {guest.email}
                  </Heading>
                </Box>
              </>
            ) : (
              <>
                <img src={guest.profilePicture} alt="guest avatar" />
                <Box ml="12px" mr="6px">
                  <Heading wordBreak="break-all" size="sm">
                    {guest.name}
                  </Heading>
                  <Text wordBreak="break-all" fontSize="xs" color="black60">
                    {guest.email}
                  </Text>
                </Box>
              </>
            )}
            <StyledCloseIcon onClick={() => handleDeleteGuest(guest.email)} />
          </Guest>
        ))}
      </ListContainer>
      {guests.length > 0 && (
        <ButtonWrapper>
          <Button onClick={handleDeleteAll} variant="secondary">
            Supprimer tous les participants
          </Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
const ListContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
`;
const Guest = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-left: auto;
  margin-right: 8px;
  flex-shrink: 0;
`;
