import { ReactComponent as CarIcon } from '@assets/icons/car.svg';
import { ReactComponent as HouseIcon } from '@assets/icons/house.svg';
import { ReactComponent as PlaneIcon } from '@assets/icons/plane.svg';
import { ReactComponent as TrainIcon } from '@assets/icons/train.svg';
import { Box } from '@chakra-ui/react';
import { Heading, Text } from '@chakra-ui/react';
import { Modal, ModalBody, ModalHeader } from '@components/Modal';
import { participantsListIntern } from '@features/assessment/mock';
import { colors } from '@theme/colors';
import styled from 'styled-components';

type ModalParticipantsProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ModalParticipants({ isOpen, onClose }: ModalParticipantsProps) {
  const transportMappings: any = {
    rail: { label: 'Train', icon: <TrainIcon /> },
    flight: { label: 'Avion', icon: <PlaneIcon /> },
    car: { label: 'Voiture', icon: <CarIcon /> },
  };
  const getIcon = (transport: any) => transportMappings[transport]?.icon || <HouseIcon />;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalHeader>Détail par participant</ModalHeader>

      <ModalBody>
        <Table>
          <thead>
            <th>
              <Text fontSize="xs" color="black60">
                Participant
              </Text>
            </th>
            <th>
              <Text fontSize="xs" color="black60">
                Localisation
              </Text>
            </th>
            <th>
              <Text fontSize="xs" color="black60">
                Recommandation
              </Text>
            </th>
            <th>
              <Text fontSize="xs" color="black60">
                Vote
              </Text>
            </th>
          </thead>
          <tbody>
            {participantsListIntern.map((guest) => (
              <tr key={guest.id}>
                <td>
                  <Participant>
                    <img alt="" src={guest.profilePicture} />
                    <Box ml="12px">
                      <Heading size="sm">{guest.name}</Heading>
                      <Text fontSize="xs" color="black60">
                        {guest.email}
                      </Text>
                    </Box>
                  </Participant>
                </td>
                <td>
                  <Text fontSize="sm" color="black" fontWeight="bold">
                    {guest.locationDeparture}
                  </Text>
                </td>
                <td>
                  {guest.recommandation ? (
                    <TagType value={guest.recommandation.value}>
                      {getIcon(guest.recommandation.mode)}{' '}
                      <Box ml="4px">{guest.recommandation.label}</Box>
                    </TagType>
                  ) : (
                    <TagWaiting>En attente</TagWaiting>
                  )}
                </td>
                <td>
                  {guest.choice ? (
                    <TagType value={guest.choice.value}>
                      {getIcon(guest.choice.mode)}{' '}
                      <Box ml="4px">{guest.choice.label}</Box>
                    </TagType>
                  ) : (
                    <TagWaiting>En attente</TagWaiting>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}

const Participant = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
  width: 100%;
`;
const Table = styled.div`
  width: 100%;

  td {
    padding: 0 16px 16px 16px;
  }

  th {
    text-align: center;
    font-weight: normal;
    padding: 0 16px 32px 16px;
    &:first-child {
      padding-left: 0;
      text-align: left;
    }
    &:nth-child(2) {
      text-align: left;
    }
  }
  tr {
    td:first-child {
      padding-left: 0;
    }
  }
`;

const TagType = styled.div<{ value: string }>`
  display: flex;
  align-items: center;
  margin-left: 4px;
  width: 100%;
  border-radius: 16px;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  background: ${(props) =>
    props.value === 'presential' ? colors.blueLight : colors.primary10};
  color: ${(props) => (props.value === 'presential' ? colors.blue : colors.primary)};

  svg {
    path {
      fill: ${(props) => (props.value === 'presential' ? colors.blue : colors.primary)};
    }
  }
`;
const TagWaiting = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 16px;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  background: ${colors.yellowBackground};
  color: ${colors.yellow};
`;
