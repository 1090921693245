import { ReactComponent as ArrowIcon } from '@assets/icons/double-arrow.svg';
import { Button, Checkbox, Input, Text } from '@chakra-ui/react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@components/Modal';
import { participantsListIntern } from '@features/assessment/mock';
import { api } from '@utils/api';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import GuestsList from './GuestsList';
import ParticipantsListCheckbox from './ParticipantsListCheckbox';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSend: () => void;
  children?: React.ReactNode;
};

export default function ModalInvitations({ isOpen, onClose, onSend }: ModalProps) {
  const [participants, setParticipants] = useState<any[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean | null>(null);
  const [emailList, setEmailList] = useState('');
  const allSelected = participants.length === selectedParticipants.length;

  useEffect(() => {
    setParticipants(participantsListIntern);
    api.get(`/users`).then((data: any) => {
      setParticipants(data.users);
    });
  }, []);

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };
  const handleSelectionChange = (selected: any) => {
    setSelectedParticipants(selected);
  };

  const handleNewGuests = () => {
    const emails = emailList.replace(/\s/g, '');
    const emailArray = emails.split(/[,;]/);
    const validEmails: string[] = [];
    const invalidEmails: string[] = [];
    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    emailArray.forEach((email) => {
      if (email !== '' && emailRegex.test(email)) {
        validEmails.push(email);
      } else if (email !== '') {
        invalidEmails.push(email);
      }
    });

    const newGuests = validEmails
      .filter((email) => !participants.some((elt) => elt.email === email))
      .map((email) => {
        const participant = participants.find((elt) => elt.email === email);
        const backgroundColor = `hsla(${Math.random() * 360}, 70%, 30%, 0.8)`;
        return participant
          ? participant
          : { email, isNewParticipant: true, bgAvatar: backgroundColor };
      });

    const newSelection = Object.values(
      [
        ...participants.filter((elt) => validEmails.includes(elt.email)),
        ...selectedParticipants,
        ...newGuests,
      ].reduce((acc, participant) => {
        acc[participant.email] = participant;
        return acc;
      }, {}),
    );

    setParticipants((prevParticipants) => [...prevParticipants, ...newGuests]);
    setSelectedParticipants(newSelection);
    setEmailList('');
  };
  const handleSendInvitations = () => {
    onSend();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl" width="80%" maxWidth="1000px">
      <ModalHeader>Choisir des participants</ModalHeader>
      <StyledModalBody>
        {participants && (
          <SelectionSection>
            <TitleSection>Choix des participants</TitleSection>
            <EmailsList>
              <Text fontSize="sm">
                Ajouter un ou des participant(s) Liste d’e-mails séparés par des “;”
              </Text>
              <EmailField>
                <Input
                  id="emailsList"
                  placeholder="E-mail du/des participant(s)"
                  value={emailList}
                  onChange={(e) => setEmailList(e.target.value)}
                />
                <Button variant="secondary" onClick={handleNewGuests}>
                  OK
                </Button>
              </EmailField>
            </EmailsList>
            <SelectAll>
              <Checkbox isChecked={allSelected} onChange={handleSelectAll}>
                Tout sélectionner
              </Checkbox>
            </SelectAll>
            <ParticipantsListCheckbox
              participants={participants}
              onSelectionChange={handleSelectionChange}
              selectAll={selectAll}
              updatedList={selectedParticipants}
            />
          </SelectionSection>
        )}

        <ArrowSection>
          <ArrowIcon />
        </ArrowSection>

        <ChoosenSection>
          <TitleSection>Liste des invités</TitleSection>
          <GuestsList
            guests={selectedParticipants}
            onSelectionChange={handleSelectionChange}
          />
        </ChoosenSection>
      </StyledModalBody>
      <ModalFooter>
        <Button variant="primary" width="100%" mr={3} onClick={handleSendInvitations}>
          Envoyer les invitations
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const StyledModalBody = styled(ModalBody)`
  display: flex;
`;

const EmailsList = styled.div`
  margin-bottom: 16px;
`;
const SelectionSection = styled.div`
  width: 50%;
`;
const ChoosenSection = styled.div`
  width: 50%;
`;
const ArrowSection = styled.div`
  margin: 0 32px;
  align-self: center;
`;
const TitleSection = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 32px;
`;
const SelectAll = styled.div`
  margin-bottom: 16px;
`;
const EmailField = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
